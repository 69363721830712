






















import { Vue, Component } from "vue-property-decorator";
import AppFormWrapper from "@/layouts/AppFormWrapper.vue";
import TokenService from "@/services/TokenService";
import WebsiteApiService from "@/services/WebsiteApiService";
import { getFirstQueryValue } from "@/utils/queryParams";

@Component({
  components: {
    AppFormWrapper,
  },
})
export default class TeaserResponseYesView extends Vue {
  gotResponse = false;
  error: string | null = null;

  get token(): string {
    return getFirstQueryValue(this.$route.query.token) ?? "";
  }

  async sendResponse(): Promise<void> {
    try {
      const tokenValidity = await TokenService.isValid(this.token);

      if (tokenValidity.expired) {
        this.error = "token";
        return;
      }

      await WebsiteApiService.teaserYes(this.token);
    } catch (e) {
      this.error = "save";
    } finally {
      this.gotResponse = true;
    }
  }

  created(): void {
    this.sendResponse();
  }
}
