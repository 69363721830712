import Axios from "axios";

interface TokenServiceResponse {
  expired: boolean;
  meta: {
    [key: string]: any;
  };
}

export default class TokenService {
  static async isValid(token: string): Promise<TokenServiceResponse> {
    const response = await Axios.get(`/api/v2/tokens/${token}/valid`);
    return response.data;
  }
}
