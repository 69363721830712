
































import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class AppFormWrapper extends Vue {
  @Prop({ required: true, type: String }) title!: string;
  @Prop({ default: 8, type: Number }) sm!: number;
  @Prop({ default: 4, type: Number }) md!: number;
  @Prop({ default: false, type: Boolean }) wide!: boolean;
  isValid = false;
}
